<template>
    <el-row class="main_MySpace">
        <el-row class="main_MySpace-content">
                <el-col> <span style="color:blue;font-weight:bold;overflow: hidden;">|</span>空间扩充</el-col>
                <el-col  style="padding:40px 0 20px 10px;">充值数量</el-col>
                <el-row :gutter="20" style="padding:0 0 0 10px;">
                        <el-col :span="2" v-for="(item,index) in productType" :key="index">
                        <el-button plain :class="{active:changeSelectStyle == index}" @click="changeStyle(index)" style="position: relative;">{{item}}G</el-button>
                        </el-col>
                </el-row>
             <el-row :gutter="20" style="padding:20px 0 0 10px;">
                    <el-col :span="2" style="widht:50px;"><el-input @keyup.native="keyup()" v-model="input" placeholder=""> </el-input></el-col>
                    <el-col :span="1" style="line-height:40px;">G</el-col>
            </el-row>
             <el-row  style="padding:25px 0 0 10px;">
                    <el-col>应支付  : <span style="color:blue;font-weight:bold;">{{space}}G</span> <span style="color:#999;"></span></el-col>
            </el-row>
             <el-row  style="padding:36px 0 0 10px;">
                 <el-button type="primary" @click="gold_payment" >金币支付</el-button>
            </el-row>
        </el-row>
    </el-row>
</template>


<script>
    import { requestAdd_storage } from "../api/request";

    export default {
    name:"MyPay",
    data () {
        return {
            input:"5",
            pickedPay:"",
            changeSelectStyle:'',
            changeSelectStyleType:'',
            productType:['5','10','50','100'],
            productPay:[{
                'name':"",
                'urlImg':require('../assets/weixin.png'),
            },{
                'name':"",
                'urlImg':require('../assets/pay.png'),
            }],
            space:5,
            vipsession:''
        }
    },
    methods : {
        changeStyle(index)
        {
           this.changeSelectStyle = index;
           this.input = this.productType[index];
            this.space=this.productType[index];
        },
        keyup()
        {
            this.input = this.common.onlyNumber(this.input)
            this.changeSelectStyle = null;
            this.space=this.input;
        },
        gold_payment(){
            let _this=this;
            if(this.space!=NaN && this.space!=0 && this.space!=undefined){
                this.$confirm('确认购买空间, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    requestAdd_storage(this.space).then(res => {
                        if(res.code==0){
                            _this.$message({
                                type: 'success',
                                message: '购买成功!'
                            });
                            setTimeout(function () {
                                _this.$router.push("/MemorySpace");
                            },1000)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消购买'
                    });
                });
            }
        }
    },
        mounted(){
            let storage_nav=this.Account.GetStorage();
            this.vipsession=storage_nav
        }
}
</script>


<style scoped>
    .main_MySpace{
        background: #d1dff9;
        height: 1000px;
        padding-top: 25px;
    }
    .main_MySpace-content
    {
        padding:50px 36px;
        width: 1350px;
        margin: 0 auto;
        background: white;
    }
    .das{
        width: 150px;
        height: 60px;
        line-height: 60px;
    }


    .active::before
    {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border: 11px solid #4ABE84;
        border-top-color: transparent;
        border-left-color: transparent;
    }

    .active::after
    {
        content: '';
        width: 1px;
        height: 5px;
        position: absolute;
        right: 3px;
        bottom: 4px;
        border: 2px solid #fff;
        border-top-color: transparent;
        border-left-color: transparent;
        transform: rotate(45deg);
    }
</style>
